/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// load fonts
//todo: add the fonts here.

import './src/styles/global.css';
import './src/styles/grid.css';
import './src/styles/normalize.css';
import './src/styles/variables.css';

export const onInitialClientRender = () => {
    window.resizeTimeout = null;
    window.addEventListener('resize', () => {
        clearTimeout(window.resizeTimeout);
        window.resizeTimeout = setTimeout(() => {
            document.documentElement.style.setProperty('--page-scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
        }, 500);
    });
};

export const onRouteUpdate = () => {
    setTimeout(() => {
        document.documentElement.style.setProperty('--page-scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
    }, 0);
};