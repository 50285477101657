exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assessment-center-js": () => import("./../../../src/pages/assessment-center.js" /* webpackChunkName: "component---src-pages-assessment-center-js" */),
  "component---src-pages-competenze-leadership-js": () => import("./../../../src/pages/competenze-leadership.js" /* webpackChunkName: "component---src-pages-competenze-leadership-js" */),
  "component---src-pages-competenze-workshop-js": () => import("./../../../src/pages/competenze-workshop.js" /* webpackChunkName: "component---src-pages-competenze-workshop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-strategie-hr-change-managment-js": () => import("./../../../src/pages/strategie-hr-change-managment.js" /* webpackChunkName: "component---src-pages-strategie-hr-change-managment-js" */)
}

